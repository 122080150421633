<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <v-row no-gutters>
        <v-col cols="12" md="10">
          <div class="mt-2 txt_search">
            <v-text-field
              v-model="find"
              hide-details
              solo
              flat
              background-color="#CFE1E1"
              class="rounded-lg"
              placeholder="Search for people"
              prepend-inner-icon="mdi-magnify"
              @keyup="fetchData"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="2" class="d-flex justify-end">
          <div class="mt-2 d-flex align-center">
            <h4 class="mr-2">Show</h4>
            <v-menu
              v-model="menuLimit"
              elevation="0"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  min-width="60px"
                  max-width="60px"
                  outlined
                  depressed
                  class="text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                >
                  <p class="ma-0 default_txt">{{ limit }}</p>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card class="popup_radius" outlined elevation="0">
                <v-list>
                  <v-list-item link @click="changeLimit(10)">
                    <v-list-item-title>10</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(50)">
                    <v-list-item-title>50</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(100)">
                    <v-list-item-title>100</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4" align="center">
        <v-col cols="12" md="4" class="py-2">
          <bullet title="List Klien" />
        </v-col>
      </v-row>
    </div>
    <v-card class="card_on_view" flat>
      <v-skeleton-loader
        class="pa-2 elevation-0"
        type="table-tbody"
        v-if="loading"
      ></v-skeleton-loader>
      <div v-if="!loading && dataSet">
        <v-data-table
          :headers="headers"
          :items="dataSet"
          hide-default-footer
          :page.sync="page"
          :items-per-page="limit"
          :single-select="false"
          class="elevation-0"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    depressed
                    class="mr-2"
                    :to="`/admin/mk/client-employee/employee/${item.id}`"
                  >
                    <v-icon dark small> mdi-eye </v-icon>
                  </v-btn>
                </template>
                <span>Lihat Karyawan Klien ini</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <div class="text-center pa-3">
          <v-pagination
            color="#052633"
            v-model="page"
            @input="fetchData"
            :length="last_page"
          ></v-pagination>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  name: "listClientEmployee",
  components: { bullet, NavbarProfile },
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
  },
  data() {
    return {
      menuLimit: false,
      loading: false,
      dataSet: null,
      find: "",
      page: 1,
      limit: 10,
      last_page: null,
      total_page: null,
      sort: "-created_at",
      filter: "",
      headers: [
        { text: "Nama", value: "nama" },
        { text: "No. Telepon", value: "telepon", width: "15%" },
        { text: "Email", value: "email" },
        { text: "Alamat", value: "alamat" },
        { text: "Action", value: "actions", sortable: false },
      ],
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "List Klien",
          disabled: true,
          href: "#",
        },
      ],
    };
  },
  mounted() {
    // jika profile ada baru get data
    let profile = setInterval(() => {
      if (this.profile) {
        this.fetchData();
        clearInterval(profile);
      }
    }, 300);
  },
  methods: {
    // ubah sorting berdasarkan
    changeSort(item) {
      this.sort = item;
      this.fetchData();
    },
    // ubah sorting berdasarkan
    changeFilter(item) {
      this.filter = item;
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/klien/list?page[size]=${this.limit}&page[number]=${this.page}&sort=${this.sort}&filter[nama]=${this.find}&filter[id]=${this.profile.data.profile.klien_id}`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.dataSet = data.data;
          this.last_page = data.last_page;
          this.total_page = data.total;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    changeLimit(val) {
      this.limit = val;
      this.fetchData();
    },
  },
};
</script>

<style scoped>
.v-pagination__item {
  box-shadow: none !important;
}
.v-pagination__navigation {
  box-shadow: none !important;
}
</style>
